import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function AutoComplete(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  const onChange = (event) => {
    props.onChange({target:{name: props.fieldName, value: event.target.textContent}})
  };

  const onTextChange = (event) => {
    props.onChange(event);
    (async () => { setOptions(await props.api(event.target.value)); })();
  };

  return (
    <Autocomplete
      onChange={onChange}
      value={props.value}
      autoComplete
      freeSolo
      //style={{ width: 300 }}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={(option) => option}
      options={options}
      renderInput={(params) => (
        <TextField
          name={props.fieldName}
          onChange={onTextChange}
          {...params}
          label={props.label}
        />
      )}
    />
  );
}
