

const accountBehaviours = [
    {
        _id:1,
        name: "Bank"
    },{
        _id:2,
        name: "CryptoC"
    },{
        _id:3,
        name: "ISA"
    },{
        _id:4,
        name: "Pension"
    },{
        _id:5,
        name: "Asset"
    },{
        _id:6,
        name: "Cash"
    }
];
var delaySim = 50;


const apiExport = {
    getAccountBehaviours: (cb) => setTimeout(() => cb(accountBehaviours), delaySim),
};

export default apiExport;
