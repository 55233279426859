import React from 'react';
import { useDispatch } from "react-redux";
import TransactionForm from '../logTransaction/TransactionForm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { saveTransaction, deleteTransaction }  from '../../actions/transactions'
import { saveTransactionForecast, deleteTransactionForecast }  from '../../actions/transaction_forecast.actions'

const TransactionDialog = (props) => {
  const dispatch = useDispatch();
  const { curTran, setCurTran, formOpen, closeDialog } = props;

  const isForecast = () => {
    return (curTran.interval_type && curTran.interval_type !== "") || curTran.interval_type === 0;
  };

  const handleDelete = () => {
    if (isForecast()){
      dispatch(deleteTransactionForecast(curTran.id));
    } else {
      dispatch(deleteTransaction(curTran.id));
    }
    closeDialog();
  };
  const handleSave = () => {
    const record = {...curTran};
    if (record.interval_type){
      record.interval_type = parseInt(record.interval_type);
    }
    if (isForecast()){
      if (record.instructed_on_date){
        dispatch(deleteTransaction(record.id));
        delete record.id;
      }
      dispatch(saveTransactionForecast(record));
    } else {
      if (record.next_date){
        dispatch(deleteTransactionForecast(record.id));
        delete record.id;
      }
      dispatch(saveTransaction(record));
    }
    closeDialog();
  };
/*
  const onTransactionChange = (event) => {
    console.log(curTran)
      const field = event.target.name;
      const value = event.target.value;
      const result = {...curTran};
      if (value === null || value === undefined){
        delete result[field];
      }else{
        result[field] = value;
      }
      setCurTran(result);
    console.log(curTran)
  };*/

  const renderTitle = () =>{
    return (curTran.id ? "Edit" : "New") + " Transaction " + (isForecast() ? "Forecast " : "");
  }

  return (
    <Dialog
        modal={false}
        open={formOpen}
        onClose={closeDialog}
        >
      <DialogTitle id="form-dialog-title">{renderTitle()}</DialogTitle>
      <DialogContent dividers={true}>
        <TransactionForm curTran={curTran}
         setCurTran={setCurTran}/>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={closeDialog}>Cancel</Button>
        {curTran.id ? <Button variant="contained"
          color="secondary"
          onClick={handleDelete}
        >Delete</Button> : null}
        <Button variant="contained"
          color="primary"
          keyboardFocused={true}
          onClick={handleSave}
        >Save</Button>
      </DialogActions>
    </Dialog>);
};

export default TransactionDialog;
