import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'balance/';

class BalanceService {

  getBalances(on, accs) {
    return axios.get(API_URL, {
      headers: authHeader(),
      params: {
         on,
         accs
      } });
  }
}

export default new BalanceService();
