import { combineReducers } from 'redux';
import accounts from './accounts'
import accountBehaviours from './accountBehaviours'
import transactions from './transactions'
import auth from "./auth";
import message from "./message";
import filter from "./filter";
import balance from "./balance";
import transaction_forecasts from './transaction_forecasts'

export default combineReducers({
    accounts,
    accountBehaviours,
    transactions,
    auth,
    message,
    filter,
    balance,
    transaction_forecasts
})
