import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { DatePicker } from '@material-ui/pickers';
import { setFilters } from '../../actions/filter.actions'
//import { getTransactions }  from '../../actions/transactions'
import Grid from '@material-ui/core/Grid';

const FilterForm = (props) => {
  const dispatch = useDispatch();
  const filter = useSelector(state => state.filter);
  const accounts = useSelector(state => state.accounts.records);

  const [filterOpen, setFilterOpen] = useState(props.initiallyVisible || filter.savedSearchId === '');
  const [curFilter, setCurFilter] = useState(filter);

  const dateOnChange = (field) => (date) => {
    const newState = {...curFilter};
    newState[field] = date;
    setCurFilter(newState);
  };
  const handleToggle = (value) => () => {
    const newState = {...curFilter};
    const pos = newState.accountsShown.indexOf(value);
    if (pos > -1){
      newState.accountsShown.splice(pos,1);
    }else {
      newState.accountsShown.push(value);
    }

    setCurFilter(newState);
  };
  const savedSearchOnChange = (ev) => {
    const payload = ev.target.value;
    const newState = {...curFilter, savedSearchId:payload};
    switch (payload) {
      case "0":
        newState.accountsShown = [];
        break;
      case "1":
        newState.accountsShown = accounts
          .filter(acc=>acc.account.report_by_default)
          .map((account) => {
            return account.account.id;
        });
        break;
      case "2":
        newState.accountsShown = accounts.map((account) => {
          return account.account.id;
        });
        break;
      default:

    };
    setCurFilter(newState);
  };
  useEffect(() => {
    if (curFilter.savedSearchId === '' && accounts.length > 0){
      savedSearchOnChange({target:{value:"1"}});
    }
  });
  const openFilterForm = () => {
      setFilterOpen(true);
  };
  const handleFilterClose = () => {
    setFilterOpen(false);
    dispatch(setFilters({...curFilter, state:"filterChanged"}));
  };
  const handleFilterCancel = () => {
    setFilterOpen(false);
  };

  return (
      <div>
      <Button variant="contained" onClick={openFilterForm}>Filter</Button>
      <Dialog
          title="Filters"
          open={filterOpen}
          onClose={handleFilterClose}
          scroll="paper"
          >
        <DialogTitle id="form-dialog-title">Filters</DialogTitle>
        <DialogContent dividers={true}>
        <Grid container >
        <Grid item xs>
          <DatePicker label="From Date"
            value={curFilter.fromDate}
            format="dd/MM/yyyy"
            onChange={dateOnChange("fromDate")} container="inline"
            name="fromDate" autoOk={true}/>
          <DatePicker label="To Date"
            value={curFilter.toDate}
            format="dd/MM/yyyy"
            onChange={dateOnChange("toDate")} container="inline"
            name="toDate" autoOk={true}/>
          <FormControl fullWidth={true}>
            <InputLabel id="saved-search-label">Saved Search</InputLabel>
            <Select
              id="saved-search"
              labelId="saved-search-label"
              value={curFilter.savedSearchId}
              required={true}
              onChange={savedSearchOnChange}>
              <MenuItem key="0" value="0">None</MenuItem>
              <MenuItem key="1" value="1">Default</MenuItem>
              <MenuItem key="2" value="2">All</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs>
          <List>
            {accounts
              .filter((acc)=>acc.account.closed_on == null)
              .sort((acc1,acc2)=>acc1.account.name > acc2.account.name)
              .map((account) => {
                const labelId = `checkbox-list-label-${account.account.id}`;

                return (
                  <ListItem key={account.account.id} role={undefined}
                    dense button onClick={handleToggle(account.account.id)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={curFilter.accountsShown.indexOf(account.account.id)>-1}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </ListItemIcon>
                    <ListItemText id={labelId} primary={account.account.name} />
                  </ListItem>
                );
            })}
            </List>
            </Grid>
            </Grid>
        </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" size="small" >
          <Button onClick={handleFilterCancel}>Cancel</Button>
          <Button color="primary" onClick={handleFilterClose} >Apply</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
    </div>);
}

export default FilterForm;
