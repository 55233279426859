import api from '../api/Api'
import * as types from '../constants/ActionTypes';

const receiveAccountBehaviours = accountBehaviours => ({
    type: types.RECEIVE_ACCOUNTBEHAVIOURS,
    accountBehaviours
})

export const getAccountBehaviours = () => dispatch => {
    api.getAccountBehaviours(accountBehaviours => {
        dispatch(receiveAccountBehaviours(accountBehaviours));
    });
};
