import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

class Clickable extends Component {
  renderLabel = () =>{
    if (this.props.isAmount){
      return new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP'}).format(this.props.label);
    } else {
      return this.props.label;
    }
  };
  getColour = () => {
    if (this.props.isAmount && this.props.label < 0){
      return '#dd1100';
    } else if (this.props.colour){
      return this.props.colour;
    } else {
      return '#000000';
    }
  };
  render() {
    return (
      <Button
        style={{height:'24px', lineHeight:'24px',
          'textTransform':'none',
          'color':this.getColour(),
          'fontStyle': this.props.fontStyle
        }}
        onClick={this.props.onClick}>{this.renderLabel()}
      </Button>);
  }
};

export default Clickable;
