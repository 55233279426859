import { SET_TRANSACTION_FORECAST_API_STATE, RECEIVE_TRANSACTION_FORECASTS, TRANSACTION_FORECAST_SAVE, TRANSACTION_FORECAST_DELETE, LOGOUT } from '../constants/ActionTypes'

const enforceTypes = (tran) =>{
  return {...tran,
    interval_type: tran.interval_type == null ? null : parseInt(tran.interval_type),
    next_date: tran.next_date == null ? null : new Date(tran.next_date),
    final_date: tran.final_date == null ? null : new Date(tran.final_date),
    SourceAccountId: tran.SourceAccountId == null ? 0 : parseInt(tran.SourceAccountId),
    DestinationAccountId: tran.DestinationAccountId == null ? 0 : parseInt(tran.DestinationAccountId),
    notes:''
  }};

const transactions = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
        case SET_TRANSACTION_FORECAST_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_TRANSACTION_FORECASTS:
            return {records:action.transactions
              .map(tran=>{return enforceTypes(tran)}), apiState:"ready"};
        case TRANSACTION_FORECAST_DELETE:
            return {records:state.records.filter(tran => tran.id !== action.id), apiState:"ready"};
        case TRANSACTION_FORECAST_SAVE:
            var found = false;
            var result = state.records.map(tran => {
                if (tran.id === action.transaction.id) {
                    found = true;
                    return enforceTypes(action.transaction);
                }
                return tran;
            });
            if (!found){
                result = [...state.records, enforceTypes(action.transaction)];
            }
            return {records:result, apiState:"ready"};
        case LOGOUT:
          return {records:[], apiState:"init"};
        default:
            return state;
    }
}
export default transactions;
