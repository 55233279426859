import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'transaction/';

class TransactionService {

  getPredictions(accs) {
    return axios.get(API_URL+'predictions/', {
      headers: authHeader(),
      params: {
         accs
      } });
  }

  getTransactions(from, to, accs) {
    //return axios.get(API_URL+`?from=${from}&to=${to}&accs=${accs}`, {
    return axios.get(API_URL, {
      headers: authHeader(),
      params: {
         from,
         to,
         accs
      } });
  }

  saveTransaction(transaction) {
    console.log(transaction);
    if (transaction.id){
      return axios.put(API_URL+transaction.id, transaction, { headers: authHeader() });
    } else {
      return axios.post(API_URL, transaction, { headers: authHeader() });
    }
  }

  deleteTransaction(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }

  autoComplete(field, partial){
    return axios.put(API_URL+"autocomplete",
      {
        partial,
        field
      }, {headers: authHeader()}
    );
  }
}

export default new TransactionService();
