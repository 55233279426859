import * as types from '../constants/ActionTypes';

const applyFilter = filters => ({
  type: types.SET_FILTER,
  filters
});

export const setFilters = (filters) => dispatch => {
  dispatch(applyFilter(filters));
};

const applyFilterStateName = state => ({
  type: types.SET_STATE_NAME,
  state
})

export const setFilterStateName = (state) => dispatch => {
  dispatch(applyFilterStateName(state));
};
