import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Clickable from '../common/Clickable';
import { connect } from 'react-redux'
import { getAllAccounts } from '../../actions/accounts'
import { getTransactions }  from '../../actions/transactions'
import { setFilters } from '../../actions/filter.actions'
import { getBalances } from '../../actions/balance.actions'
import { isSameDay } from 'date-fns'
import ReportsHelper from '../../helpers/reports'


class StatementList extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        reportDays: []
      };
    }
    setStateName = (name) => {this.props.setFilters({...this.props.filter, state:name});};

    componentDidMount() {
      this.setStateName("gotBalances");
    }
    componentDidUpdate(){
      this.processState();
    }
    processState = () => {
      switch (this.props.filter.state){
        case "gotBalances":
          if (this.props.balance.length < 1){
            console.log("Filter state is gotBalances but balance state is empty. Skipping.");
            break;
          }
          this.setStateName("processingData");
          console.log(`calculating report data from ${this.props.filter.fromDate} to ${this.props.filter.toDate}`);
          const newState = ReportsHelper.generateDailyBalances(
            this.props.filter,
            this.props.balance,
            this.props.transaction_forecasts,
            this.props.transactions.records)
          this.setState(newState);
          this.setStateName("reportReady");
          break;
        case "transactionDeleted":
        case "transactionSaved":
          this.setStateName("gotBalances");
          break;
        default:
          break;
        };
    }
    formatTransactionAmount = tran => {
      return (tran.SourceAccountId > 0 && tran.DestinationAccountId === 0)?-tran.amount:tran.amount;
    }
    getAccount = id  => {
      var result = {};
      if (this.props.accounts != null){
        let foundAcc = this.props.accounts.filter(acc => acc.account.id === id);
        if (foundAcc != null && foundAcc.length > 0){
          result = foundAcc[0].account;
        }
      }
      return result;
    }
    getTransaction = id  => {
      var result = {};
      if (this.props.transactions.records != null){
        let foundTran = this.props.transactions.records.filter(tran => tran.id === id);
        if (foundTran != null && foundTran.length > 0){
          result = foundTran[0];
        }
      }
      return result;
    }
    getBalance = (id, entry) => {
      const bal = entry.balances.filter(acc => acc.id === id);
      return bal && bal.length > 0 ? bal[0].amount.toFixed(2) : 0;
    }
    newFromDate = date => { return d => {this.props.openTransactionForm({
      state:"occurred",
      instructed_on_date:date,
      statement_date:date,
      SourceAccountId: 0,
      DestinationAccountId: 0,
      notes:''
    });} }
    newFromBalance = (date, acc) => { return (d,a) => {this.props.openTransactionForm({
      state:"occurred",
      instructed_on_date:date,
      statement_date:date,
      SourceAccountId:acc,
      DestinationAccountId: 0,
      notes:''
    });} }
    editTran = tran => { return t => {this.props.openTransactionForm(tran);}}
    renderDateLabel = date => {
      if (isSameDay(date,new Date())){
        return "Today";
      }else{
        return `${date.toLocaleDateString()} ${date.toString().substring(0, 2)}`;
      }
    }

    render () {
    return (<div>
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader >
            <TableHead >
                <TableRow>
                    <TableCell padding="none" align="center">Date</TableCell>
                    <TableCell padding="none" align="center" >Amount</TableCell>
                    <TableCell padding="none" align="center" >Description</TableCell>
                    {this.props.filter.accountsShown.map((accountId, index)=>{
                    return (
                        <TableCell key={"headerItem"+index} align="center"
                         padding="none">{this.getAccount(accountId).name}</TableCell>);
                    })}
                </TableRow>
            </TableHead>
            <TableBody>
                {this.state.reportDays.map((entry, index)=>{
                    return (
                    <TableRow hover key={"item"+index}>
                        <TableCell padding="none">
                          <Clickable
                            label={this.renderDateLabel(entry.date)}
                            onClick={this.newFromDate(entry.date)}/>
                        </TableCell>
                        <TableCell align="left" padding="none">
                            {entry.transactions.map((tran, index)=>{
                                return (
                                <div key={"dayItemAmnt"+index}>
                                    <Clickable
                                      label={this.formatTransactionAmount(tran)}
                                      onClick={this.editTran(tran)}
                                      isAmount={true}/>
                                </div>);
                            })}
                            {entry.forecasts.map((tran, index)=>{
                                return (
                                <div key={"dayItemAmnt"+index}>
                                    <Clickable
                                      label={this.formatTransactionAmount(tran)}
                                      onClick={this.editTran(tran)}
                                      isAmount={true}/>
                                </div>);
                            })}
                        </TableCell>
                        <TableCell align="left" padding="none">
                            {entry.transactions.map((tran, index)=>{
                                return (
                                <div key={"dayItem"+index} >
                                    <Clickable label={tran.description}
                                      fontStyle={tran.state === "predicted" ? 'italic' : 'normal'}
                                      onClick={this.editTran(tran)}/>
                                </div>);
                            })}
                            {entry.forecasts.map((tran, index)=>{
                                return (
                                <div key={"dayItem"+index} >
                                    <Clickable label={tran.description}
                                      fontStyle={tran.state === "predicted" ? 'italic' : 'normal'}
                                      onClick={this.editTran(tran)}
                                      colour={"#2222ff"}/>
                                </div>);
                            })}
                        </TableCell>
                        {this.props.filter.accountsShown.map((accountId, index)=>{
                            return (
                            <TableCell key={"dayBalItem"+index} padding="none" align="right">
                                <Clickable label={this.getBalance(accountId, entry)}
                                    onClick={this.newFromBalance(entry.date, accountId)}
                                    isAmount={true}/>
                            </TableCell>);
                        })}
                    </TableRow>);
                })}
            </TableBody>
        </Table>
      </TableContainer>
    </div>);
    }
}

const mapStateToProps = state => ({
    accounts: state.accounts.records,
    transactions: state.transactions,
    filter: state.filter,
    transaction_forecasts: state.transaction_forecasts.records
  })

export default connect(mapStateToProps,
  { getTransactions, getAllAccounts, setFilters, getBalances })(StatementList);
