import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DatePicker } from '@material-ui/pickers';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default class AccountForm extends React.Component {
    mapOnChange = (name, payload) => {
        this.props.onChange({
            target: {
                name: name,
                value: payload
            }
        });
    };
    behaviourOnChange = (ev) => {
        this.mapOnChange("type", ev.target.value);
    };
    dateOnChange = (event, date) => {
        this.mapOnChange("closed_on", event);
    };
    toggleOnChange = (event, checked) => {
        this.mapOnChange("report_by_default", checked);
    }
    render() {
        return (
            <div>
              <FormControl fullWidth={true}>
                <TextField label="Name of the account" hintText="Name" name="name"
                    value={this.props.curAcc.account.name} required={true} onChange={this.props.onChange} />
                </FormControl>
                <FormControl fullWidth={true} required={true}>
                  <InputLabel id="account-type-label">Account type</InputLabel>
                  <Select
                      id="account-type"
                      labelId="account-type-label"
                      value={this.props.curAcc.account.type}
                      onChange={this.behaviourOnChange}>
                      {this.props.accountBehaviours.map((behaviour, index)=>{
                          return (<MenuItem key={behaviour._id} value={behaviour._id}>
                               {behaviour.name}</MenuItem>)
                      })}
                  </Select><br/>
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField label="Sort code" hintText="Sort Code" name="sort_code"
                    value={this.props.curAcc.account.sort_code} onChange={this.props.onChange} />
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField label="Account number" hintText="Account number" name="account_number"
                    value={this.props.curAcc.account.account_number} onChange={this.props.onChange} /><br/>
                </FormControl>
                <FormControl fullWidth={true}>
                  <TextField label="Provider e.g. Bank name" hintText="Provider" name="provider"
                    value={this.props.curAcc.account.provider} onChange={this.props.onChange} />
                </FormControl>
                <FormControl fullWidth={true}>
                  <DatePicker label="Closed on date" hintText="Closed Date" format="dd/MM/yyyy"
                    value={this.props.curAcc.account.closed_on} onChange={this.dateOnChange} container="inline"
                    name="closed_on" autoOk={true}/><br />
                </FormControl>
                <FormControl fullWidth={true}>
                  <FormControlLabel control={
                    <Switch onChange={this.toggleOnChange}
                      checked={this.props.curAcc.account.report_by_default} />}
                      label="Report by default"/>
                </FormControl>
            </div>
        );
    }
}
