import * as types from '../constants/ActionTypes';
import AccountService from "../services/accounts.service"
import { handleAPIError } from "./common.actions"

const setAPIState = apiState =>({
  type: types.SET_ACCOUNTS_API_STATE,
  apiState
});

const receiveAccounts = accounts => ({
    type: types.RECEIVE_ACCOUNTS,
    accounts
});

export const getAllAccounts = () => dispatch => {
  dispatch(setAPIState("get"));
  return AccountService.getAccounts().then(
    response => {
      dispatch(receiveAccounts(response.data));
    },
    handleAPIError(dispatch));
};

const saveAccountAction = account => ({
    type: types.ACCOUNT_SAVE,
    account
});

export const saveAccount = account => dispatch => {
  dispatch(setAPIState("save"));
  return AccountService.saveAccount(account.account).then(
    response => {
      console.log(response);
      if (!account.account.id){
        account.account.id = response.data.id;
      }
      dispatch(saveAccountAction(account));
    },
    handleAPIError(dispatch));
}

const deleteAccountAction = accountId => ({
    type: types.ACCOUNT_DELETE,
    accountId
});

export const deleteAccount = accountId => dispatch => {
  dispatch(setAPIState("delete"));
  return AccountService.deleteAccount(accountId).then(
    response => {
    dispatch(deleteAccountAction(accountId))
  },
  handleAPIError(dispatch));
}
