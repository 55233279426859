import { SET_FILTER, SET_STATE_NAME, LOGOUT } from "../constants/ActionTypes";
import { subDays, addDays } from 'date-fns'

const initialState = {
  fromDate: subDays(new Date(), 10),
  toDate: addDays(new Date(), 30),
  accountsShown: [],
  savedSearchId: ''
};

export default function reduce(state = initialState, action) {
  const { type, filters } = action;

  switch (type) {
    case SET_FILTER:
      return { ...filters };
    case SET_STATE_NAME:
      return {...state, state:action.state};
    case LOGOUT:
      return [];

    default:
      return state;
  }
}
