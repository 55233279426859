export const RECEIVE_ACCOUNTS = 'RECEIVE_ACCOUNTS';
export const ACCOUNT_SAVE = 'ACCOUNT_SAVE';
export const ACCOUNT_DELETE = 'ACCOUNT_DELETE';
export const SET_ACCOUNTS_API_STATE = 'SET_ACCOUNTS_API_STATE';

export const RECEIVE_ACCOUNTBEHAVIOURS = 'RECEIVE_ACCOUNTBEHAVIOURS';

export const RECEIVE_TRANSACTIONS = 'RECEIVE_TRANSACTIONS';
export const TRANSACTION_SAVE = 'TRANSACTION_SAVE';
export const TRANSACTION_DELETE = 'TRANSACTION_DELETE';
export const RECEIVE_TRANSACTION_PREDICTIONS = 'RECEIVE_TRANSACTION_PREDICTIONS';


export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";


export const SET_FILTER = "SET_FILTER";
export const SET_STATE_NAME = "SET_STATE_NAME";


export const RECEIVE_BALANCES = "RECEIVE_BALANCES";


export const RECEIVE_TRANSACTION_FORECASTS = 'RECEIVE_TRANSACTION_FORECASTS';
export const TRANSACTION_FORECAST_SAVE = 'TRANSACTION_FORECAST_SAVE';
export const TRANSACTION_FORECAST_DELETE = 'TRANSACTION_FORECAST_DELETE';
export const SET_TRANSACTION_FORECAST_API_STATE = 'SET_TRANSACTION_FORECAST_API_STATE';
