import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from 'react-router-dom';
import FilterForm from "../common/FilterForm"
import '../../../node_modules/react-vis/dist/style.css';
import {XYPlot, LineSeries, VerticalGridLines, HorizontalGridLines, XAxis, YAxis, Crosshair, DiscreteColorLegend } from 'react-vis';
import { isSameDay } from 'date-fns'
import ReportsHelper from '../../helpers/reports'
import { setFilterStateName } from '../../actions/filter.actions'
import Paper from '@material-ui/core/Paper';

const ChartsPage = () => {
  const [graphData, setGraphData] = React.useState([]);
  const [crosshairValues, setCrosshairValues] = React.useState([]);
  const dispatch = useDispatch();

  const auth = useSelector(state => state.auth);
  const filter = useSelector(state => state.filter);
  const transactions = useSelector(state => state.transactions);
  const balance = useSelector(state => state.balance);
  const transaction_forecasts = useSelector(state => state.transaction_forecasts.records);
  const accounts = useSelector(state => state.accounts.records);

  useEffect(() => {
    switch (filter.state){
      case "filterChanged":
        setGraphData([]);
        break;
      case "transactionDeleted":
      case "transactionSaved":
        dispatch(setFilterStateName("gotBalances"));
        break;
      case "gotBalances":
        if (filter && balance && transaction_forecasts && transactions && balance.length > 0){
          setGraphData(ReportsHelper.generateDailyBalances(filter,balance,transaction_forecasts,transactions.records));
        }
        break;
      default:
    }
  },[filter, dispatch,balance,transaction_forecasts,transactions]);

  if (!auth.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const myFormatter = (t, i) =>{
    return (<tspan x="0" dy="1em">{isSameDay(new Date(), new Date(t)) ? "Today" : new Date(t).toLocaleDateString()}</tspan>);
  }

  const onMouseLeave = () => {
    setCrosshairValues([]);
  };

  const getAccountName=(id)=>{
    return accounts.filter(ac=>ac.account.id===id)[0].account.name;
  };

  const formatAmount=(amount)=>{
    return new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP'}).format(amount);
  };

  const onNearestX = (value, {index}) => {
    setCrosshairValues([
      {x:graphData.reportDays[index].date,
        title:'Total', y:formatAmount(graphData.reportDays[index].total)},
        ...graphData.reportDays[index].balances.map(bal=>{
          return {
            title: getAccountName(bal.id),
            y: formatAmount(bal.amount)
          };
      })]);
  };

  return (<>
        <FilterForm initiallyVisible={graphData.length === 0}/>
        <div style={{textAlign:'center'}}>
        <XYPlot onMouseLeave={onMouseLeave} margin={{bottom:80, left:60}} width={1000} height={600}
        yDomain={[graphData.minBal, graphData.maxBal]}>
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis tickLabelAngle={-60} tickFormat={myFormatter} />
          <YAxis />
          { filter.accountsShown.map(id =>{
            return (filter.state !== "filterChanged" && graphData.reportDays) ? (
              <LineSeries key={"ls"+id} data={
              graphData.reportDays.map(dayVals=>{
                  return {x:new Date(dayVals.date), y:dayVals.balances.filter(b=>b.id===id)[0].amount};
                }
              )} />) : null;
            })
          }
          {graphData.reportDays ? (<LineSeries onNearestX={onNearestX} data={
            graphData.reportDays.map(dayVals=>{
                return {x:new Date(dayVals.date), y:dayVals.total};
              }
            )} />) : null}
          <Crosshair
            values={crosshairValues}>
            <Paper elevation={3} style={{
              minWidth:'20em',
              fontSize:'12px',
              padding:'5px'
            }}>
              <p style={{textAlign:'center'}}>
                {crosshairValues.length > 0 ? crosshairValues[0].x.toLocaleDateString() : ""}
              </p>
              {crosshairValues.map((elem, ind) =>
                  <p key={ind}><span style={{width:'100px',display:'inline-block'}}>{elem.y}</span><span>{elem.title}</span></p>
              )}
            </Paper>
          </Crosshair>
        </XYPlot>
          <DiscreteColorLegend items={[
            ...filter.accountsShown.map((accountId)=>{
              return getAccountName(accountId);
            }),'Total']} orientation="horizontal"/>
        </div>
    </>);
};

export default ChartsPage;
