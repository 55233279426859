import * as types from '../constants/ActionTypes';
import TransactionService from "../services/transactions.service"
import { handleAPIError } from "./common.actions"
import { setFilterStateName } from './filter.actions'

const receivePredictions = predictions => ({
    type: types.RECEIVE_TRANSACTION_PREDICTIONS,
    predictions
});

export const getPredictions = (accs) => dispatch => {
    return TransactionService.getPredictions(accs).then(
      response => {
        dispatch(receivePredictions(response.data));
      },
      handleAPIError(dispatch));
};

const receiveTransactions = transactions => ({
    type: types.RECEIVE_TRANSACTIONS,
    transactions
});

export const getTransactions = (from, to, accs) => dispatch => {
    return TransactionService.getTransactions(from, to, accs).then(
      response => {
        //console.log("Handling received transactions");
        dispatch(receiveTransactions(response.data));
        dispatch(setFilterStateName("gotTransactions"));
        //console.log("Dispatched transaction handler");
      },
      handleAPIError(dispatch));
};

const saveTransactionAction = transaction => ({
    type: types.TRANSACTION_SAVE,
    transaction
});

export const saveTransaction = transaction => dispatch => {
  return TransactionService.saveTransaction(transaction).then(
    response => {
      console.log(response);
      if (!transaction.id){
        transaction.id = response.data.id;
      }
      dispatch(saveTransactionAction(transaction));
      dispatch(setFilterStateName("transactionSaved"));
    },
    handleAPIError(dispatch));
}

const deleteTransactionAction = id => ({
    type: types.TRANSACTION_DELETE,
    id
});

export const deleteTransaction = id => dispatch => {
    return TransactionService.deleteTransaction(id).then(
      response => {
      dispatch(deleteTransactionAction(id));
      dispatch(setFilterStateName("transactionDeleted"));
    },
    handleAPIError(dispatch));
}
