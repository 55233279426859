import { RECEIVE_TRANSACTION_PREDICTIONS, RECEIVE_TRANSACTIONS, TRANSACTION_SAVE, TRANSACTION_DELETE, LOGOUT } from '../constants/ActionTypes'

const enforceTypes = (tran) =>{
  return {
    ...tran,
    instructed_on_date: tran.instructed_on_date == null ? null : new Date(tran.instructed_on_date),
    statement_date: tran.statement_date == null ? null : new Date(tran.statement_date),
    SourceAccountId: tran.SourceAccountId == null ? 0 : tran.SourceAccountId,
    DestinationAccountId: tran.DestinationAccountId == null ? 0 : tran.DestinationAccountId,
    notes: tran.notes == null ? '' : tran.notes,
  }
};
const blankState = {records:[], apiState:'init', predictions:[]};
const transactions = (state = blankState, action) => {
    switch (action.type) {
        case RECEIVE_TRANSACTION_PREDICTIONS:
            return {...state, apiState:'ready',
              predictions:action.predictions
                .map(tran=>{return enforceTypes(tran)})};
        case RECEIVE_TRANSACTIONS:
            return {...state, apiState:'ready',
              records:action.transactions
                .map(tran=>{return enforceTypes(tran)})};
        case TRANSACTION_DELETE:
            return {
              records:state.records.filter(tran => tran.id !== action.id),
              predictions:state.predictions.filter(tran => tran.id !== action.id),
              apiState:'ready'
            };
        case TRANSACTION_SAVE:
            var found = false;
            var records = state.records.map(tran => {
                if (tran.id === action.transaction.id) {
                    found = true;
                    return enforceTypes(action.transaction);
                }
                return tran;
            });
            if (!found){
                records = [...state.records, enforceTypes(action.transaction)];
            }
            var predictions = state.predictions;
            if (action.transaction.state==="predicted"){
              found = false;
                predictions = predictions.map(tran => {
                  if (tran.id === action.transaction.id) {
                    found = true;
                    return enforceTypes(action.transaction);
                }
                return tran;
                });
                if (!found){
                  predictions = [...state.predictions, enforceTypes(action.transaction)];
                }
            } else {
              predictions = predictions.filter(p=>p.id!==action.transaction.id);
            }
            return {
              records,
              predictions,
              apiState:'ready'
            };
        case LOGOUT:
          return blankState;
        default:
            return state;
    }
}
export const getTransaction = (state, id) => state.filter(tran => tran.id === id);

export default transactions;
