import React from "react";
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import { isSameDay } from 'date-fns'
import TransactionDialog from '../logTransaction/TransactionDialog';
import { saveTransaction, deleteTransaction }  from '../../actions/transactions'

const Dashboard = () => {
  const dispatch = useDispatch();
  const [formOpen, setFormOpen] = React.useState(false);
  const [curTran, setCurTran] = React.useState({});
  const [selected, setSelected] = React.useState([]);
  const auth = useSelector(state => state.auth);
  const accounts = useSelector(state => state.accounts);
  const transactions = useSelector(state => state.transactions);

  if (!auth.isLoggedIn) {
    return <Redirect to="/login" />;
  }
  if (accounts.apiState === "ready" && accounts.records.length === 0){
    return <Redirect to="/settings" />;
  }
  const openTransactionForm = (transaction) => {
    setCurTran({...transaction});
    setFormOpen(true);
  };

  const closeDialog = () => {
    setFormOpen(false);
  };

  const isSelected = id => selected.indexOf(id) !== -1;

  const toggleSelected = id => (ev) => {
    if (ev.target.checked){
      setSelected([...selected, id]);
    }else{
      setSelected(selected.filter(sel=>sel!==id));
    }
    ev.stopPropagation();
  };
  const markSelectedOccurred = () =>{
    selected.forEach(id=>{
      const tran = transactions.predictions.find(t=>t.id===id);
      dispatch(saveTransaction({...tran, state:"occurred"}));
    });
  };

  const deleteSelected = () =>{
    selected.forEach(id=>{
      dispatch(deleteTransaction(id));
    });
  };

  const renderDateLabel = date => {
    if (!date){
      return 'No date';
    }
    if (isSameDay(date,new Date())){
      return "Today";
    }else{
      return `${date.toLocaleDateString()} ${date.toString().substring(0, 2)}`;
    }
  };

  const accountName = id => {
    const acc = accounts.records.find(a=>a.account.id===id);
    return acc?acc.account.name:'3rd party';
  };

  return (
    <div className="container" style={{padding:5}}>
      <TransactionDialog
        curTran={curTran}
        formOpen={formOpen}
        closeDialog={closeDialog}
        setCurTran={setCurTran}/>
      <h2>{auth.user.username}'s Dashboard</h2>
      <h3>Past Predictions</h3>
      <ButtonGroup variant="contained" size="small" disabled={selected.length===0}>
        <Button color="primary"   onClick={markSelectedOccurred}>Mark Occurred</Button>
        <Button color="secondary" onClick={deleteSelected}>Delete</Button>
      </ButtonGroup>
      <TableContainer component={Paper}>
        <Table size="small" stickyHeader >
            <TableHead >
                <TableRow>
                    <TableCell padding="none"></TableCell>
                    <TableCell padding="none">Date</TableCell>
                    <TableCell padding="none">Amount</TableCell>
                    <TableCell padding="none">Description</TableCell>
                    <TableCell padding="none">From</TableCell>
                    <TableCell padding="none">To</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              {transactions.predictions
                .sort((p1,p2)=>p1.instructed_on_date>p2.instructed_on_date).map(p=>{
                return <TableRow hover key={"item"+p.id}
                  onClick={()=>openTransactionForm(p)}>
                    <TableCell padding="none">
                      <Checkbox
                        edge="start"
                        checked={isSelected(p.id)}
                        disableRipple
                        onClick={toggleSelected(p.id)}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      {renderDateLabel(p.instructed_on_date)}
                    </TableCell>
                    <TableCell padding="none">
                      {p.amount}
                    </TableCell>
                    <TableCell padding="none">
                      {p.description}
                    </TableCell>
                    <TableCell padding="none">
                      {accountName(p.SourceAccountId)}
                    </TableCell>
                    <TableCell padding="none">
                      {accountName(p.DestinationAccountId)}
                    </TableCell>
                  </TableRow>;
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default Dashboard;
