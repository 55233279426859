import { combineReducers } from 'redux';
import { RECEIVE_ACCOUNTBEHAVIOURS } from '../constants/ActionTypes'

const accountBehaviours = (state, action) => {
    switch (action.type) {/*
        case ACCOUNT_SAVE:
            return {
                ...state
            };*/
        default:
            return state;
    }
}

const byId = (state = {}, action) =>{
    switch (action.type){
        case RECEIVE_ACCOUNTBEHAVIOURS:
            return {
                ...state,
                ...action.accountBehaviours.reduce((obj, accountBehaviour)=> {
                    obj[accountBehaviour._id] = accountBehaviour;
                    return obj;
                }, {})
            };
        default:
            const { accountBehaviourId } = action
            if (accountBehaviourId) {
                return {
                    ...state,
                    [accountBehaviourId]: accountBehaviours(state[accountBehaviourId], action)
                };                
            }
            return state;
    }
}

const visibleIds = (state = [], action) => {
    switch (action.type){
        case RECEIVE_ACCOUNTBEHAVIOURS:
            return action.accountBehaviours.map(accountBehaviour => accountBehaviour._id);
        default:
            return state;
    }
}

export default combineReducers({
    byId,
    visibleIds
})

export const getAccountBehaviour = (state, id) =>
    state.byId[id];

export const getAccountBehaviours = state =>
    state.visibleIds.map(id => getAccountBehaviour(state, id))