import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { logout } from "../../actions/auth";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import HomeIcon from '@material-ui/icons/Home';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MeetingRoomIcon from '@material-ui/icons/MeetingRoom';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ListIcon from '@material-ui/icons/List';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import BallotIcon from '@material-ui/icons/Ballot';
import SettingsIcon from '@material-ui/icons/Settings';

function ListItemLink(props) {
  const { icon, primary, to, onClick } = props;

  const renderLink = React.useMemo(
    () => React.forwardRef((itemProps, ref) => <Link to={to} ref={ref} {...itemProps} />),
    [to],
  );

  return (
    <li>
      <ListItem button component={renderLink} onClick={onClick}>
        {icon ? <ListItemIcon style={{'minWidth': '35px'}}>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainMenu = () => {
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };
  const renderLoginOptions = () => {
    if (isLoggedIn){
      return (
        <ListItemLink to="/login" primary="Log Out" icon={<MeetingRoomIcon />} onClick={logOut} />);
    } else {
      return (<>
        <ListItemLink to="/login" primary="Log In" icon={<MeetingRoomIcon />} />
        <ListItemLink to="/register" primary="Register" icon={<PermIdentityIcon />} /></>);
    }
  }

  return (<div id="mainMenu">
        <List>
        {isLoggedIn?
          (<><ListItemLink to="/dashboard" primary="Dashboard" icon={<DashboardIcon />} />
          <ListItemLink to="/statements" primary="Statements" icon={<ListIcon />} />
          <ListItemLink to="/charts" primary="Charts" icon={<ShowChartIcon />} />
          <ListItemLink to="/budgets" primary="Budgets" icon={<BallotIcon />} />
          <ListItemLink to="/settings" primary="Settings" icon={<SettingsIcon />} /></>) : null}
          {renderLoginOptions()}
        </List>
    </div>);
};

export default MainMenu;


/*      <form>
          <input id="search" type="search" placeholder="Search" aria-label="Search" />
      </form>*/
/*<ListItemLink to="/" primary="Home" icon={<HomeIcon />} />*/
/*<li><NavLink to="/logtransaction">Log Transaction</NavLink></li>*/
/*<li><NavLink to="/income">Income</NavLink></li>*/
/*<li><NavLink to="/planning">Planning</NavLink></li>*/
/*<li><NavLink to="/help">Help</NavLink></li>*/
