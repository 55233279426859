import React from 'react';
import { useSelector } from "react-redux";
import StatementList from './StatementList';
//import { getTransactions }  from '../../actions/transactions'
import FilterForm from "../common/FilterForm"
import TransactionDialog from '../logTransaction/TransactionDialog';
import { Redirect } from 'react-router-dom';

const StatementsPage = () => {
  const [formOpen, setFormOpen] = React.useState(false);
  const [curTran, setCurTran] = React.useState({});

//  const filter = useSelector(state => state.filter);
  const accounts = useSelector(state => state.accounts.records);
  const transactions = useSelector(state => state.transactions);
  const balance = useSelector(state => state.balance);
  const auth = useSelector(state => state.auth);

  if (!auth.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const openTransactionForm = (transaction) => {
    setCurTran({...transaction});
    setFormOpen(true);
  };

  const closeDialog = () => {
    setFormOpen(false);
  }
/*
  const onFilterChanged = () =>{
    if (filter != null &&
        filter.accountsShown != null &&
        filter.accountsShown.length > 0){
      getTransactions(
        filter.fromDate,
        filter.toDate,
        filter.accountsShown
      );
    }
  };*/
  return (<div>
      <FilterForm  />
      <TransactionDialog
        curTran={curTran}
        formOpen={formOpen}
        closeDialog={closeDialog}
        setCurTran={setCurTran}/>
      <StatementList
        accounts={accounts}
        transactions={transactions}
        balance={balance}
        openTransactionForm={openTransactionForm} />
    </div>);
};

export default StatementsPage;
