import { RECEIVE_BALANCES, LOGOUT } from '../constants/ActionTypes'

const balances = (state = [], action) => {
    switch (action.type) {
        case RECEIVE_BALANCES:
            return action.balances;
        case LOGOUT:
          return [];
        default:
            return state;
    }
}

export default balances;
