import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'transaction_forecast/';

class TransactionForecastService {

  getTransactionForecasts(accs) {
    return axios.get(API_URL, {
      headers: authHeader(),
      params: {
         accs
      } });
  }

  saveTransactionForecast(transaction) {
    console.log(transaction);
    if (transaction.id){
      return axios.put(API_URL+transaction.id, transaction, { headers: authHeader() });
    } else {
      return axios.post(API_URL, transaction, { headers: authHeader() });
    }
  }

  deleteTransactionForecast(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new TransactionForecastService();
