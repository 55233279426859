import { eachDayOfInterval, isSameDay } from 'date-fns'
import ForecastHelper from './forecast'

const generateDailyBalances = (filter,balance,transaction_forecasts,transactions) => {
  let lastBalance = filter.accountsShown.map((accountId)=>{
    return {id:accountId,
      // Making copy to ensure order matches
     amount:balance.filter(bal=>bal.id===""+accountId)[0].amount}
  });
  let tempForecasts = transaction_forecasts;
  let maxBal = 0;
  let minBal = 0;
  const newState = {
    reportDays:eachDayOfInterval({ start: filter.fromDate, end: filter.toDate })
      .map((loop)=>{
        tempForecasts = ForecastHelper.moveForecastsTo(tempForecasts,loop);
        const trans = transactions.filter(tran=>isSameDay(new Date(tran.instructed_on_date),loop));
        const forecasts = tempForecasts
          .filter(tf=>isSameDay(new Date(tf.next_date),loop) &&
            (filter.accountsShown.includes(tf.SourceAccountId) ||
            filter.accountsShown.includes(tf.DestinationAccountId))
          );
        const balances = filter.accountsShown.map((accountId, i)=>{
          let amount = lastBalance[i].amount;
          if (trans){
            trans.forEach(transaction=>{
              if (transaction.SourceAccountId && transaction.SourceAccountId === accountId){
                amount = amount - parseFloat(transaction.amount);
              }
              if (transaction.DestinationAccountId && transaction.DestinationAccountId === accountId){
                amount = amount + parseFloat(transaction.amount);
              }
            })
          }
          if (forecasts){
            forecasts.forEach(transaction=>{
              if (transaction.SourceAccountId && transaction.SourceAccountId === accountId){
                amount = amount - parseFloat(transaction.amount);
              }
              if (transaction.DestinationAccountId && transaction.DestinationAccountId === accountId){
                amount = amount + parseFloat(transaction.amount);
              }
            })
          }
          minBal = minBal < amount ? minBal : amount;
          return {id:accountId, amount};
        });
        lastBalance = balances;
        let total = balances.reduce((accum,val)=>accum+val.amount,0);
        maxBal = maxBal > total ? maxBal : total;
        return {
          date:loop,
          transactions:trans,
          balances,
          forecasts,
          total
        }
      }),
      maxBal,
      minBal
    };
    return newState;
}
const ReportsHelper = {
   generateDailyBalances
}
export default ReportsHelper;
