import React from 'react';
import { useSelector } from "react-redux";
import TransactionDialog from '../logTransaction/TransactionDialog';
import Button from '@material-ui/core/Button';
import { Redirect } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const BudgetsPage = () => {
  const [formOpen, setFormOpen] = React.useState(false);
  const [curTran, setCurTran] = React.useState({});
  const transaction_forecasts = useSelector(state => state.transaction_forecasts.records);
  const auth = useSelector(state => state.auth);
  const accounts = useSelector(state => state.accounts);

  if (!auth.isLoggedIn) {
    return <Redirect to="/login" />;
  }

  const editTran = (tf) => () => {
    setCurTran({...tf});
    setFormOpen(true);
  };

  const openTransactionForm = () => {
    setCurTran({
      next_date: new Date(),
      SourceAccountId: 0,
      DestinationAccountId: 0,
      interval_type: 1,
      notes:''
    });
    setFormOpen(true);
  };

  const closeDialog = () => {
    setFormOpen(false);
  }

  const formatInterval = (tf, text) => {
    return `${tf.interval_amount} ${text}${tf.interval_amount > 1 ? "s" : ""}`;
  }
  const getIntervalType = (tf) => {
    switch (tf.interval_type) {
      case 0: return formatInterval(tf,"day");
      case 1: return formatInterval(tf,"week");
      case 2: return formatInterval(tf,"month");
      case 3: return formatInterval(tf,"year");
      default: console.log("Oops, forecast interval type invalid");
    }
  }
  const formatTransactionAmount = tran => {
    return new Intl.NumberFormat('en-GB',{ style: 'currency', currency: 'GBP'}).format(
      (tran.SourceAccountId > 0 && tran.DestinationAccountId === 0)?-tran.amount:tran.amount);
  }

  const getAccountName = (id) =>{
    if (!id || id === 0){
      return "3rd Party";
    } else {
      let t = accounts.records.filter(a=>a.account.id === id)[0];
      if (!t) {
        console.log(id);
        console.log(accounts);
      }
      return t.account.name;
    }
  }
  const getAccountsText = (tf) => {
    return `${getAccountName(tf.SourceAccountId)} to ${getAccountName(tf.DestinationAccountId)}`;
  }

  return (<>
    <Button variant="contained" onClick={openTransactionForm}>New</Button><br/>
    <TransactionDialog
      curTran={curTran}
      formOpen={formOpen}
      closeDialog={closeDialog}
      setCurTran={setCurTran}/>
    <TableContainer component={Paper}>
      <Table aria-label="forecast transactions table">
        <TableHead>
          <TableRow>
            <TableCell align="right">Next Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Accounts</TableCell>
            <TableCell>Interval</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transaction_forecasts
            .sort((tf1,tf2)=>{return tf1.next_date > tf2.next_date})
            .map((tf)=>{
            return (
            <TableRow key={tf.id} onClick={editTran(tf)} hover>
              <TableCell align="right">{tf.next_date.toLocaleDateString()} {tf.next_date.toString().substring(0, 2)}</TableCell>
              <TableCell align="right">{formatTransactionAmount(tf)}</TableCell>
              <TableCell>{tf.description}</TableCell>
              <TableCell>{getAccountsText(tf)}</TableCell>
              <TableCell>{getIntervalType(tf)}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
    </>);
};

export default BudgetsPage;
