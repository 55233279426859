
const getApiUrl = () =>{
  if (process.env.NODE_ENV === "development") {
    return 'http://192.168.0.122:8080/api/';
  } else {
    return 'https://financesight.com/api/';
  }
};
const CommonServices = {
  getApiUrl
};

export default CommonServices;
