import { SET_ACCOUNTS_API_STATE, RECEIVE_ACCOUNTS, ACCOUNT_SAVE, ACCOUNT_DELETE, LOGOUT } from '../constants/ActionTypes'

const accounts = (state = {records:[],apiState:"init"}, action) => {
    switch (action.type) {
        case SET_ACCOUNTS_API_STATE:
            return {...state,apiState:action.apiState}
        case RECEIVE_ACCOUNTS:
            return {records:action.accounts.map(acc=>{
              return {...acc.account,
                account:{
                  ...acc.account,
                  closed_on: acc.account.closed_on == null ? null : new Date(acc.account.closed_on)
                }};
            }), apiState:"ready"};
        case ACCOUNT_DELETE:
            return {records:state.records.filter(acc => acc.account.id !== action.accountId), apiState:"ready"};
        case ACCOUNT_SAVE:
            var found = false;
            // Go through the array and if found then update the data for existing record
            // Otherwise leave them alone
            var result = state.records.map(acc => {
                if (acc.account.id === action.account.account.id) {
                    found = true;
                    return {...acc, account:action.account.account};
                }
                return acc;
            });
            if (!found){
                result = [...state.records, {account:action.account.account}];
            }
            return {records:result, apiState:"ready"};
        case LOGOUT:
          return {records:[], apiState:"init"};
        default:
            return state;
    }
}
export const getAccount = (state, id) => state.filter(acc => acc._id === id);

export default accounts;
