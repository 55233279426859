import axios from 'axios';
import authHeader from './auth-header';
import CommonServices from './common.service';

const API_URL = CommonServices.getApiUrl() + 'account/';

class AccountService {

  getAccounts() {
    return axios.get(API_URL, { headers: authHeader() });
  }

  saveAccount(account) {
    console.log(account);
    if (account.id){
      return axios.put(API_URL+account.id, account, { headers: authHeader() });
    } else {
      return axios.post(API_URL, account, { headers: authHeader() });
    }
  }

  deleteAccount(id) {
    return axios.delete(API_URL+id, { headers: authHeader() });
  }
}

export default new AccountService();
