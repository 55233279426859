import React from 'react';
import AccountList from './AccountList';
import AccountForm from './AccountForm';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { connect } from 'react-redux'
import { getAccountBehaviours } from '../../reducers/accountBehaviours'
import { saveAccount, deleteAccount } from '../../actions/accounts'

//const AccountTab = ({accounts}) => (
class AccountTab extends React.Component {
    state = {
      formOpen: this.props.accounts.apiState === "ready" && this.props.accounts.records.length === 0,
      curAcc: this.props.accounts.apiState === "ready" && this.props.accounts.records.length === 0 ?
        {account:{}} : {}
    };

    handleOpen = (account) => () => {
        this.setState({
          formOpen: true,
          curAcc: account
                    ? {...account, account:{...account.account}}
                    : {account:{report_by_default:true, type:1}}});
    };

    handleClose = () => {
      this.setState({formOpen: false});
    };

    handleCancel = () => {
        this.handleClose();
    }

    handleDelete = () => {
        this.handleClose();
        this.props.deleteAccount(this.state.curAcc.account.id);
    }

    handleSave = () => {
        this.handleClose();
        this.props.saveAccount(this.state.curAcc);
    }

    onAccountChange = (event) => {
        var field = event.target.name;
        var value = event.target.value;
        this.setState(prevState => {
            var result = {...prevState};
            result.curAcc.account[field] = value;
            return result;
        });
    };

    render() {
      return (
      <div>
          <Button variant="contained" onClick={this.handleOpen()}>New</Button>
          <AccountList items={this.props.accounts.records} onAccountSelected={this.handleOpen}/>
          <Dialog
              title="Account Form"
              open={this.state.formOpen}
              onClose={this.handleCancel}
              >
            <DialogTitle id="form-dialog-title">Account Form</DialogTitle>
            <DialogContent dividers={true}>
              <AccountForm curAcc={this.state.curAcc} onChange={this.onAccountChange}
                  accountBehaviours={this.props.accountBehaviours} />
            </DialogContent>
            <DialogActions>
              <ButtonGroup variant="contained" size="small" >
                <Button onClick={this.handleCancel}>Cancel</Button>
                <Button color="secondary" onClick={this.handleDelete}>Delete</Button>
                <Button color="primary" keyboardFocused={true} onClick={this.handleSave}>
                  Save</Button>
              </ButtonGroup>
            </DialogActions>
          </Dialog>
      </div>);
    }
}

const mapStateToProps = state => ({
    accounts: state.accounts,
    accountBehaviours: getAccountBehaviours(state.accountBehaviours)
  })

  export default connect(mapStateToProps, { saveAccount, deleteAccount })(AccountTab)
