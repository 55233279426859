import { logout } from "./auth";

export const handleAPIError = dispatch => (error) => {
  console.log((error.response &&
      error.response.data &&
      error.response.data.message) ||
    error.message ||
    error.toString());
  if (error.response && error.response.status === 401){
    dispatch(logout());
  }
};
