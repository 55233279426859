import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getAllAccounts } from '../../actions/accounts'
import { getAccountBehaviours } from '../../actions/accountBehaviours'
import { getTransactionForecasts } from '../../actions/transaction_forecast.actions'
import ForecastHelper from '../../helpers/forecast'
import { getPredictions, getTransactions, saveTransaction }  from '../../actions/transactions'
import { saveTransactionForecast }  from '../../actions/transaction_forecast.actions'
import { getBalances } from '../../actions/balance.actions'
import { setFilterStateName } from '../../actions/filter.actions'

const StateManager = () => {
  const [sysState, setSysState] = React.useState({});
  const dispatch = useDispatch();

  const filter = useSelector(state => state.filter);
  const accounts = useSelector(state => state.accounts);
  //const transactions = useSelector(state => state.transactions);
  const transaction_forecasts = useSelector(state => state.transaction_forecasts);
  //const balance = useSelector(state => state.balance);
  const auth = useSelector(state => state.auth);

  const transition = () => {
    if (auth.isLoggedIn){
      if (accounts.apiState === "init"){
          dispatch(getAccountBehaviours());
          dispatch(getAllAccounts());
          return "Getting accounts";
      } else if (transaction_forecasts.apiState === "init"){
        if (accounts.apiState === "ready"){
          dispatch(getTransactionForecasts(accounts.records.map(acc=>acc.id)));
          return "Getting transaction forecasts";
        } else{
          return "Awaiting accounts...";
        }
      } else if (!sysState.prunedForecasts && transaction_forecasts.apiState === "ready"){
        const hits = [];
        const tempForecasts = ForecastHelper.moveForecastsTo(
          transaction_forecasts.records.filter(tf => tf.next_date <= new Date())
          , new Date(), hits);
        hits.forEach(hit=>{
          delete hit.id;
          delete hit.interval_type;
          hit.instructed_on_date = hit.next_date;
          hit.statement_date = hit.next_date;
          hit.state = "predicted";
          dispatch(saveTransaction(hit));
        });
        tempForecasts.forEach(tf=>{
          dispatch(saveTransactionForecast(tf));
        });
        setSysState({...sysState,prunedForecasts:true});
      } else if (sysState.prunedForecasts &&
        !sysState.gotPastPredictions){
        dispatch(getPredictions(accounts.records.map(acc=>acc.id)));
        setSysState({...sysState,gotPastPredictions:true});
        //return "Getting past transaction predictions";
      }
      if (filter.state === "filterChanged"){
        dispatch(setFilterStateName("gettingTransactions"));
        dispatch(getTransactions(
          filter.fromDate,
          filter.toDate,
          filter.accountsShown
        ));
        return "Getting Transactions";
      } else if (filter.state === "gotTransactions"){
        dispatch(setFilterStateName("gettingBalances"));
        dispatch(getBalances(
          filter.fromDate,
          filter.accountsShown
        ));
        return "Getting Balances";
      }
      return "Ready";
    }
    return "Logged out";
  }

  return (<div style={{fontSize:"8pt", marginTop:20, color:'#aaa'}}>{transition()}</div>);
};


export default StateManager;
