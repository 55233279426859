import * as types from '../constants/ActionTypes';
import BalanceService from "../services/balance.service"
import { handleAPIError } from "./common.actions"
import { setFilterStateName } from './filter.actions'

const receiveBalances = balances => ({
    type: types.RECEIVE_BALANCES,
    balances
});

export const getBalances = (on, accs) => dispatch => {
    dispatch(receiveBalances([]));
    return BalanceService.getBalances(on, accs).then(
      response => {
        //setTimeout(function () { dispatch(receiveBalances(response.data)); }, 1000);
        //console.log("Handling received Balances");
        dispatch(receiveBalances(response.data));
        dispatch(setFilterStateName("gotBalances"));
        //console.log("Dispatched balance handler");
      },
      handleAPIError(dispatch));
};
