import React from 'react';
//import MuiThemeProvider from '@material-ui/styles/MuiThemeProvider';
import { BrowserRouter, Route } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

import Header from '../components/common/Header';
import MainMenu from '../components/common/MainMenu';
import StateManager from '../components/common/StateManager';
import Login from '../components/login/login.component';
import Register from '../components/login/register.component';
import Dashboard from '../components/dashboard/dashboard.component';
import HomePage from '../components/home/HomePage';
import LogTransactionPage from '../components/logTransaction/LogTransactionPage';
import StatementsPage from '../components/statements/StatementsPage';
import ChartsPage from '../components/charts/ChartsPage';
import BudgetsPage from '../components/budgets/BudgetsPage';
import SettingsPage from '../components/settings/SettingsPage';

const AppView = () => {

  return (<BrowserRouter>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className="container">
          <Header />
          <MainMenu />
          <Paper id="mainContent">
              <Route path="/" exact component={HomePage} />
              <Route path="/login" component={Login} />
              <Route path="/register" component={Register} />
              <Route path="/dashboard" component={Dashboard} />
              <Route path="/logtransaction" component={LogTransactionPage} />
              <Route path="/statements" component={StatementsPage} />
              <Route path="/charts" component={ChartsPage} />
              <Route path="/budgets" component={BudgetsPage} />
              <Route path="/settings" component={SettingsPage} />
              <StateManager/>
          </Paper>
      </div>
  </MuiPickersUtilsProvider>
</BrowserRouter>);
}

export default AppView;
