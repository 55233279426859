import React from 'react';

const HomePage = () => (
    <div style={{padding:15}}>
        <h2>Welcome to FinanceSight v4 using ReactJS</h2>
    </div>
);


export default HomePage;
