import { addDays, addMonths, addYears } from 'date-fns'

const advanceForecast = tf =>{
  switch (tf.interval_type) {
    case 0: tf.next_date = addDays(tf.next_date, tf.interval_amount); break;
    case 1: tf.next_date = addDays(tf.next_date, 7*tf.interval_amount); break;
    case 2: tf.next_date = addMonths(tf.next_date, tf.interval_amount); break;
    case 3: tf.next_date = addYears(tf.next_date, tf.interval_amount); break;
    default: console.log("Oops, forecast interval type invalid");
  }
}
const moveForecastsTo = (tfs,to,hits) => {
  return tfs.map(tf=>{
    const tfOut = {...tf};
    while (tfOut.next_date < to){
      if (hits) {
        hits.push({...tfOut});
      }
      advanceForecast(tfOut);
    }
    return tfOut;}
  )
  .filter(tf=>tf.final_date == null || tf.final_date >= tf.next_date);
}
const ForecastHelper = {
  advanceForecast,
  moveForecastsTo
}
export default ForecastHelper;
