import React from 'react';
//import FontIcon from 'material-ui/FontIcon';
import { connect } from 'react-redux'
import { getAccountBehaviours } from '../../reducers/accountBehaviours'
import { saveTransaction, deleteTransaction } from '../../actions/transactions'
import TransactionForm from './TransactionForm'

class LogTransactionPage extends React.Component {
    state = {
      formOpen: false,
      curTran: {}
    };

    handleOpen = (parm1) => {
        let acc = parm1.length == null ? {} : Object.create(this.props.accounts[parm1[0]]);
        this.setState({formOpen: true, curTran: acc});
    };

    handleClose = () => {
      this.setState({formOpen: false});
    };

    handleCancel = () => {
        this.handleClose();
    }

    handleDelete = () => {
        this.handleClose();
        this.props.deleteTransaction(this.state.curTran._id);
    }

    handleSave = () => {
        this.handleClose();
        this.props.saveTransaction(this.state.curTran);
    }

    onTransactionChange = (event) => {
        var field = event.target.name;
        var value = event.target.value;
        this.setState(prevState => {
            var result = {...prevState};
            result.curTran[field] = value;
            return result;
        });
    };

    render() {
        return (
            <div>
                <TransactionForm curTran={this.state.curTran} onChange={this.onTransactionChange}
                    accounts={this.props.accounts} />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    accounts: state.accounts,
    accountBehaviours: getAccountBehaviours(state.accountBehaviours)
  })

export default connect(mapStateToProps, { saveTransaction, deleteTransaction })(LogTransactionPage)
