import React from 'react';
import { useSelector, useDispatch } from "react-redux";
import 'date-fns';
import TextField from '@material-ui/core/TextField';
import NativeSelect from '@material-ui/core/NativeSelect';
import IconButton from '@material-ui/core/IconButton';
import {SwapHoriz} from '@material-ui/icons';
import { DatePicker } from '@material-ui/pickers';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import AutoComplete from '../common/AutoComplete';
import TransactionService from '../../services/transactions.service';
import { handleAPIError } from "../../actions/common.actions"

const TransactionForm = (props) => {
  const dispatch = useDispatch();
  const { curTran, setCurTran } = props;
  const [noExpiry, setNoExpiry] = React.useState(curTran.final_date == null);
  const accounts = useSelector(state => state.accounts.records);

  const isForecast = () => {
    return (curTran.interval_type && curTran.interval_type !== "") || curTran.interval_type === 0;
  }
  const api = (field) => (val) => {
    return TransactionService.autoComplete(field, val)
      .then(res =>{
        return res.data;
    },
    handleAPIError(dispatch));
  };

  const mapChanges = (kvp) => {
    const result = {...curTran};
    kvp.forEach(item => {
      if (item.value || item.value === 0){
        result[item.name] = item.value;
      }else{
        delete result[item.name];
      }
    });
    setCurTran(result);
  };
  const mapOneChange = (name, value) => {
    mapChanges([{name, value}]);
  };
  const handleChange = (event) => {
    const {name, value} = event.target
    mapOneChange(name, value);
  }
  const accountOnChange = (field) => (event, key, payload) => {
      mapOneChange(field, event.target.value);
  };
  const dateOnChange = (field) => (date) => {
    const changes = [{name:field, value: date}];
    if (field === "instructed_on_date" && curTran.statement_date == null){
      changes.push({name:"statement_date", value: date});
    }
    mapChanges(changes);
  };
  const toggleNoExpiry = () => {
    setNoExpiry(!noExpiry);
    if (!noExpiry && curTran.final_date){
      mapOneChange("final_date", null);
    }
  };
  const swapAccounts = () =>{
    mapChanges([
      {name:"SourceAccountId", value: curTran.DestinationAccountId},
      {name:"DestinationAccountId", value: curTran.SourceAccountId}]);
  }

  return (
      <div>
      { isForecast() ?
        <><FormControl required>
          <DatePicker label="Next Date"
            value={curTran.next_date}
            format="dd/MM/yyyy"
            onChange={dateOnChange("next_date")} container="inline"
            name="next_date" autoOk={true}/>
        </FormControl>
        {noExpiry?null:
        <FormControl>
          <DatePicker label="Final Date"
            value={curTran.final_date}
            format="dd/MM/yyyy"
            onChange={dateOnChange("final_date")} container="inline"
            name="final_date" autoOk={true}/>
        </FormControl>}
        <FormControlLabel
          control={<Switch checked={noExpiry} onChange={toggleNoExpiry} name="noExpiry" />}
          label="No Expiry"
        /></>
         :
        <><FormControl required>
          <DatePicker label="Instructed On Date"
            value={curTran.instructed_on_date}
            format="dd/MM/yyyy"
            onChange={dateOnChange("instructed_on_date")} container="inline"
            name="instructed_on_date" autoOk={true}/>
        </FormControl>
        <FormControl>
          <DatePicker label="Statement Date"
            value={curTran.statement_date}
            format="dd/MM/yyyy"
            onChange={dateOnChange("statement_date")} container="inline"
            name="statement_date" autoOk={true}/>
        </FormControl></>}
        <br />

        <TextField label="Amount" name="amount"
          value={curTran.amount} onChange={handleChange} type="number" />
        { isForecast() ? null :
        <FormControl component="fieldset">
          <FormLabel component="legend">State</FormLabel>
          <RadioGroup aria-label="state" name="state" value={curTran.state} onChange={handleChange}>
            <FormControlLabel value="predicted" control={<Radio />} label="Predicted" />
            <FormControlLabel value="occurred" control={<Radio />} label="Occurred" />
            <FormControlLabel value="reconciled" control={<Radio />} label="Reconciled" />
          </RadioGroup>
        </FormControl>
        }
        <br />

        <AutoComplete api={api("description")} value={curTran.description}
          onChange={handleChange} label={"Description"}
          fieldName={"description"} />
        { isForecast() ? null :
          <AutoComplete api={api("statement_description")} value={curTran.statement_description}
            onChange={handleChange} label={"Statement Description"}
            fieldName={"statement_description"} />}<br />

        <FormControl required>
          <InputLabel shrink htmlFor="SourceAccountId-native-label-placeholder">
            Source Account
          </InputLabel>
          <NativeSelect
            value={curTran.SourceAccountId}
            onChange={accountOnChange("SourceAccountId")}
            inputProps={{
              name: 'SourceAccountId',
              id: 'SourceAccountId-native-label-placeholder',
            }}
          >
            <option key={"src0"} value={0}>3rd Party</option>
            {accounts
              .filter((acc)=>acc.account.closed_on == null)
              .sort((acc1,acc2)=>acc1.account.name > acc2.account.name)
              .map((account, index)=>{
                return (<option key={"src"+account.account.id} value={account.account.id}>{account.account.name}</option>)
            })}
          </NativeSelect>
        </FormControl>
        <IconButton aria-label="Swap From/To" onClick={swapAccounts}>
          <SwapHoriz />
        </IconButton>
        <FormControl required>
          <InputLabel shrink htmlFor="DestinationAccountId-native-label-placeholder">
            Destination Account
          </InputLabel>
          <NativeSelect
            value={curTran.DestinationAccountId}
            onChange={accountOnChange("DestinationAccountId")}
            inputProps={{
              name: 'DestinationAccountId',
              id: 'DestinationAccountId-native-label-placeholder',
            }}
          >
            <option key={"dest0"} value={0}>3rd Party</option>
            {accounts
              .filter((acc)=>acc.account.closed_on == null)
              .sort((acc1,acc2)=>acc1.account.name > acc2.account.name)
              .map((account, index)=>{
                return (<option key={"dest"+account.account.id} value={account.account.id}>{account.account.name}</option>)
            })}
          </NativeSelect>
        </FormControl><br />

        <AutoComplete api={api("categories")} value={curTran.categories}
          onChange={handleChange} label={"Categories"}
          fieldName={"categories"} /><br />

        { isForecast() ?
        (
        <TextField label="Interval Amount" name="interval_amount"
            value={curTran.interval_amount} onChange={handleChange} />)
        : null}
        <FormControl>
          <InputLabel shrink htmlFor="interval_type-native-label-placeholder">
          Forecast Interval
          </InputLabel>
          <NativeSelect
          value={curTran.interval_type}
          onChange={accountOnChange("interval_type")}
          inputProps={{
            name: 'interval_type',
            id: 'interval_type-native-label-placeholder',
          }}
          >
          {[{n:"N/A (one off)", v:""},
            {n:"Days", v:0},
            {n:"Weeks", v:1},
            {n:"Months", v:2},
            {n:"Years", v:3}]
            .map(opt=>{return (<option key={"interv"+opt.v} value={opt.v}>{opt.n}</option>)})}
          </NativeSelect>
        </FormControl><br/>

        { isForecast() ? null :
        <TextField label="Notes" name="notes" multiline
          value={curTran.notes} onChange={handleChange} />
        }
        </div>
    );
}

export default TransactionForm;
