import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

class AccountList extends React.Component {
    formatDate = (date) => { return date == null ? "Active" : date.toLocaleDateString(); }
    render () {
    return (<div>
      <TableContainer component={Paper}>
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Sort Code</TableCell>
                    <TableCell>Account Number</TableCell>
                    <TableCell>Provider</TableCell>
                    <TableCell>Closed On</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {this.props.items.map((account, index)=>{
                    return (
                    <TableRow key={account.account.id} onClick={this.props.onAccountSelected(account)} hover>
                        <TableCell>{account.account.name}</TableCell>
                        <TableCell>{account.account.sort_code}</TableCell>
                        <TableCell>{account.account.account_number}</TableCell>
                        <TableCell>{account.account.provider}</TableCell>
                        <TableCell>{this.formatDate(account.account.closed_on)}</TableCell>
                    </TableRow>);
                })}

            </TableBody>
        </Table>
      </TableContainer>
    </div>);
    }
};

export default AccountList;
