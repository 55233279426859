import * as types from '../constants/ActionTypes';
import TransactionForecastService from "../services/transactions_forecast.service"
import { handleAPIError } from "./common.actions"
import { setFilterStateName } from './filter.actions'

const setAPIState = apiState =>({
  type: types.SET_TRANSACTION_FORECAST_API_STATE,
  apiState
});

const receiveTransactionForecasts = transactions => ({
    type: types.RECEIVE_TRANSACTION_FORECASTS,
    transactions
});

export const getTransactionForecasts = (accs) => dispatch => {
  dispatch(setAPIState("get"));
    return TransactionForecastService.getTransactionForecasts(accs).then(
      response => {
        dispatch(receiveTransactionForecasts(response.data));
      },
      handleAPIError(dispatch));
};

const saveTransactionForecastAction = transaction => ({
    type: types.TRANSACTION_FORECAST_SAVE,
    transaction
});

export const saveTransactionForecast = transaction => dispatch => {
  dispatch(setAPIState("save"));
  return TransactionForecastService.saveTransactionForecast(transaction).then(
    response => {
      console.log(response);
      if (!transaction.id){
        transaction.id = response.data.id;
      }
      dispatch(saveTransactionForecastAction(transaction));
      dispatch(setFilterStateName("transactionSaved"));
    },
    handleAPIError(dispatch));
}

const deleteTransactionForecastAction = id => ({
    type: types.TRANSACTION_FORECAST_DELETE,
    id
});

export const deleteTransactionForecast = id => dispatch => {
  dispatch(setAPIState("delete"));
    return TransactionForecastService.deleteTransactionForecast(id).then(
      response => {
      dispatch(deleteTransactionForecastAction(id));
      dispatch(setFilterStateName("transactionDeleted"));
    },
    handleAPIError(dispatch));
}
